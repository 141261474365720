@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
/* @font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}

@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}

@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}

$astralSistersFont: AstralSisters;
$clanProBlkFont: ClanProBlk;
$clanProBoldFont: ClanProBold;
$clanProMediuFont: ClanProMedium;
$clanProRegularFont: ClanProRegular;
$clanProRgNewsFont: ClanProRgNews;
$clanProUltraFont: ClanProUltra;
$sarabunBoldFont: SarabunBold;
$sarabunExtraBoldFont: SarabunExtraBold;
$sarabunLightFont: SarabunLight;
$sarabunLightItalicFont: SarabunLightItalic;
$sarabunMediumFont: SarabunMedium;
$sarabunRegularFont: SarabunRegular;
$sarabunSemiBoldFont: SarabunSemiBold;
$playFairFont: "Playfair Display", serif; */
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.salesforce-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  padding-top: 5rem;
  padding-bottom: 10rem;
}
.salesforce-section .salesforce-subcontainer {
  width: 1024px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  row-gap: 5rem;
}
.salesforce-section .salesforce-subcontainer .salesforce-title {
  color: #014da1;
  font-weight: 500;
  width: 70%;
  font-size: 2rem;
  text-align: center;
}
.salesforce-section .salesforce-subcontainer .sf-info-container {
  display: flex;
  align-items: center;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.salesforce-section .salesforce-subcontainer .sf-video-container {
  position: relative;
}
.salesforce-section .salesforce-subcontainer .deco-img {
  position: absolute;
  z-index: -1;
  width: 7rem;
  top: -5rem;
  left: -3rem;
}
.salesforce-section .salesforce-subcontainer .sf-video {
  width: 100%;
}
.salesforce-section .salesforce-subcontainer .sf-anchor {
  display: flex;
  justify-content: center;
}
.salesforce-section .salesforce-subcontainer .salesforce-logo {
  width: 60%;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .salesforce-section .salesforce-subcontainer {
    margin: 0 1rem;
  }
  .salesforce-section .salesforce-subcontainer .salesforce-title {
    font-size: 1.5rem;
  }
  .salesforce-section .salesforce-subcontainer .sf-info-container {
    flex-direction: column;
    row-gap: 5rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .salesforce-section .salesforce-subcontainer {
    margin: 0 10%;
  }
  .salesforce-section .salesforce-subcontainer .salesforce-title {
    font-size: 1.5rem;
  }
  .salesforce-section .salesforce-subcontainer .sf-info-container {
    flex-direction: column;
    row-gap: 5rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .salesforce-section .salesforce-subcontainer {
    margin: 0 10%;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .salesforce-subcontainer {
    margin: 0 1rem;
  }
}/*# sourceMappingURL=SalesforceSection.css.map */