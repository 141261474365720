@import "../../sass/variables";

.job-offer-page-banner-section {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  .jopb-banner-img {
    height: 15rem;
    width: auto;
    filter: saturate(0.5);
    object-fit: cover;
  }
  .job-offer-page-banner-subcontainer {
    width: 100%;
    display: flex;
    position: absolute;
    justify-content: center;
    .jopb-job-info-container {
      width: 1024px;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .jopb-ji-title {
      color: $basicColorPrimary;
      font-size: 2rem;
      font-weight: 500;
    }
    .jopb-ji-more-info-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 1rem;
    }
    .jopb-ji-mi-container {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
    }
    .jopb-ji-mi-icon {
      height: 1rem;
      width: auto;
    }
    .jopb-ji-mi-txt {
      color: $basicColorPrimary;
      font-size: 1.1rem;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .jopb-job-info-container {
    width: 90% !important;
    .jopb-ji-title {
      font-size: 1.25rem !important;
    }
    .jopb-ji-mi-txt {
      font-size: 0.8rem !important;
    }
    .jopb-ji-more-info-container {
      column-gap: 2rem;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .jopb-job-info-container {
    width: 80% !important;
    .jopb-ji-mi-txt {
      font-size: 0.8rem !important;
    }
    .jopb-ji-more-info-container {
      column-gap: 2rem;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .jopb-job-info-container {
    width: 90% !important;
  }
}
