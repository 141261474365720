@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
/* @font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}

@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}

@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}

$astralSistersFont: AstralSisters;
$clanProBlkFont: ClanProBlk;
$clanProBoldFont: ClanProBold;
$clanProMediuFont: ClanProMedium;
$clanProRegularFont: ClanProRegular;
$clanProRgNewsFont: ClanProRgNews;
$clanProUltraFont: ClanProUltra;
$sarabunBoldFont: SarabunBold;
$sarabunExtraBoldFont: SarabunExtraBold;
$sarabunLightFont: SarabunLight;
$sarabunLightItalicFont: SarabunLightItalic;
$sarabunMediumFont: SarabunMedium;
$sarabunRegularFont: SarabunRegular;
$sarabunSemiBoldFont: SarabunSemiBold;
$playFairFont: "Playfair Display", serif; */
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #014da1;
  border-radius: 0.5rem;
  position: relative;
  box-shadow: 10px 6px 18px -8px rgba(0, 0, 0, 0.67);
}
.form-container .header-title-container {
  background-color: #014da1;
  border: 1px solid #014da1;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  color: #ffffff;
  padding: 1rem 3rem;
  text-align: center;
  font-size: 0.9rem;
}
.form-container .form-element {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  margin: 3rem 2rem;
}
.form-container .form-element .grid-inputs-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  row-gap: 1.5rem;
}
.form-container .form-element input {
  margin-top: 0.5rem;
  padding: 0.5rem 0;
  padding-left: 1rem;
  border-radius: 0.5rem !important;
  border: 2px solid #e2e2e2 !important;
  width: 92%;
  font-family: "Roboto", sans-serif;
}
.form-container .form-element .input-comment {
  margin-top: 0.5rem;
  padding: 0.5rem 0;
  padding-left: 1rem;
  border-radius: 0.5rem !important;
  border: 2px solid #e2e2e2 !important;
  width: 96%;
  font-family: "Roboto", sans-serif;
  height: 6rem;
  resize: none;
}
.form-container .form-element select {
  margin-top: 0.5rem;
  padding: 0.5rem 0;
  padding-left: 1rem;
  border-radius: 0.5rem;
  border: 2px solid #e2e2e2 !important;
  width: 100%;
  background-color: #e2e2e2;
  font-family: "Roboto", sans-serif;
}
.form-container .form-element .mandatory-text {
  font-size: 0.8rem;
  color: #FF0007;
}
.form-container .form-element .hide {
  opacity: 0;
}
.form-container .form-element .advise-label {
  display: flex;
  align-items: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  font-size: 0.85rem;
}
.form-container .form-element .error {
  color: #FF0007;
}
.form-container .form-element .checkbox-input {
  width: 2rem;
}
.form-container .form-element .submit-btn {
  width: 100%;
  height: 3rem;
  color: #014da1;
  background-color: #42ffff;
  border: 1px solid #42ffff;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
}
.form-container .form-element .submit-btn:hover {
  background-color: #014da1;
  border: 1px solid #014da1;
  color: #e2e2e2;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .grid-inputs-container {
    grid-template-columns: none !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .grid-inputs-container {
    grid-template-columns: none !important;
  }
}/*# sourceMappingURL=FormWWu.css.map */