@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
/* @font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}

@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}

@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}

$astralSistersFont: AstralSisters;
$clanProBlkFont: ClanProBlk;
$clanProBoldFont: ClanProBold;
$clanProMediuFont: ClanProMedium;
$clanProRegularFont: ClanProRegular;
$clanProRgNewsFont: ClanProRgNews;
$clanProUltraFont: ClanProUltra;
$sarabunBoldFont: SarabunBold;
$sarabunExtraBoldFont: SarabunExtraBold;
$sarabunLightFont: SarabunLight;
$sarabunLightItalicFont: SarabunLightItalic;
$sarabunMediumFont: SarabunMedium;
$sarabunRegularFont: SarabunRegular;
$sarabunSemiBoldFont: SarabunSemiBold;
$playFairFont: "Playfair Display", serif; */
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.digital-sign-section {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  background-color: #009FE2;
}
.digital-sign-section .digital-sign-subcontainer {
  width: 1024px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.digital-sign-section .digital-sign-subcontainer .ds-info-container {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  align-self: flex-end;
  margin-bottom: 7rem;
}
.digital-sign-section .digital-sign-subcontainer .ds-info-title {
  font-weight: 500;
}
.digital-sign-section .digital-sign-subcontainer .man-img {
  width: 100%;
  height: 100%;
  z-index: 1;
}
.digital-sign-section .digital-sign-subcontainer .ds-info-btns-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.digital-sign-section .digital-sign-subcontainer .btn-container {
  width: 35%;
}
.digital-sign-section .digital-sign-btn-subcontainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  padding: 1.5rem 0;
  background-color: #014da1;
}
.digital-sign-section .digital-sign-btn-subcontainer .dsb-title {
  color: #ffffff;
  font-weight: 500;
}
.digital-sign-section .digital-sign-btn-subcontainer .ds-btn-subsection {
  width: 3rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .digital-sign-section {
    margin-top: 0;
    padding-top: 50%;
  }
  .digital-sign-section .digital-sign-subcontainer {
    width: 100%;
    display: unset;
  }
  .digital-sign-section .digital-sign-subcontainer .ds-info-container {
    margin: 0 1rem 7rem 1rem;
    align-items: center;
  }
  .digital-sign-section .digital-sign-subcontainer .ds-info-btns-container {
    flex-direction: column;
    row-gap: 1.5rem;
  }
  .digital-sign-section .digital-sign-subcontainer .btn-container {
    width: 75%;
  }
  .digital-sign-section .man-img {
    display: none;
  }
  .digital-sign-section .digital-sign-btn-subcontainer {
    flex-direction: column;
    row-gap: 1rem;
  }
  .digital-sign-section .dsb-title {
    margin: 0 1rem;
  }
  .digital-sign-section .ds-btn-subsection {
    display: flex;
    justify-content: center;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .digital-sign-section {
    margin-top: 0;
    padding-top: 45%;
  }
  .digital-sign-section .digital-sign-subcontainer {
    width: 100%;
    display: unset;
  }
  .digital-sign-section .digital-sign-subcontainer .ds-info-container {
    margin: 0 10% 7rem 10%;
    align-items: center;
  }
  .digital-sign-section .digital-sign-subcontainer .ds-info-btns-container {
    flex-direction: column;
    row-gap: 1.5rem;
  }
  .digital-sign-section .digital-sign-subcontainer .btn-container {
    width: 75%;
  }
  .digital-sign-section .man-img {
    display: none;
  }
  .digital-sign-section .digital-sign-btn-subcontainer {
    flex-direction: column;
    row-gap: 1rem;
  }
  .digital-sign-section .dsb-title {
    margin: 0 1rem;
  }
  .digital-sign-section .ds-btn-subsection {
    display: flex;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .digital-sign-section {
    margin-top: 0;
    padding-top: 15%;
  }
  .digital-sign-section .digital-sign-subcontainer {
    width: 100%;
  }
  .digital-sign-section .digital-sign-subcontainer .ds-info-container {
    margin: 0 0 0 10%;
    align-self: center;
  }
  .digital-sign-section .digital-sign-subcontainer .ds-info-btns-container {
    flex-direction: column;
    row-gap: 1.5rem;
  }
  .digital-sign-section .digital-sign-subcontainer .btn-container {
    width: 75%;
  }
  .digital-sign-section .dsb-title {
    margin: 0 1rem;
  }
  .digital-sign-section .ds-btn-subsection {
    display: flex;
    justify-content: center;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .digital-sign-subcontainer {
    margin-left: 5%;
  }
}/*# sourceMappingURL=DigitalSignSection.css.map */