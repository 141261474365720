@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
/* @font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}

@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}

@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}

$astralSistersFont: AstralSisters;
$clanProBlkFont: ClanProBlk;
$clanProBoldFont: ClanProBold;
$clanProMediuFont: ClanProMedium;
$clanProRegularFont: ClanProRegular;
$clanProRgNewsFont: ClanProRgNews;
$clanProUltraFont: ClanProUltra;
$sarabunBoldFont: SarabunBold;
$sarabunExtraBoldFont: SarabunExtraBold;
$sarabunLightFont: SarabunLight;
$sarabunLightItalicFont: SarabunLightItalic;
$sarabunMediumFont: SarabunMedium;
$sarabunRegularFont: SarabunRegular;
$sarabunSemiBoldFont: SarabunSemiBold;
$playFairFont: "Playfair Display", serif; */
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.banner-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}
.banner-section .banner-subcontainer {
  width: 90%;
  display: grid;
  grid-template-columns: 60% 40%;
  -moz-column-gap: 3rem;
       column-gap: 3rem;
  align-items: flex-start;
}
.banner-section .banner-subcontainer .banner-info-container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.banner-section .banner-subcontainer .banner-title {
  width: 70%;
  font-size: 2rem;
  font-weight: 300;
  color: #014da1;
}
.banner-section .banner-subcontainer .main-img {
  width: 100%;
  height: auto;
}
.banner-section .banner-subcontainer .second-img {
  width: 50%;
  height: 100%;
}
.banner-section .banner-subcontainer .banner-btn-info-container {
  width: 55%;
  display: flex;
  position: relative;
}
.banner-section .banner-subcontainer .btn-banner-info {
  width: 100%;
  border: 3px solid #42ffff;
  padding: 0.3rem 2rem;
  border-radius: 1.5rem;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #014da1;
  font-size: 1.2rem;
}
.banner-section .banner-subcontainer .btn-banner-info:hover {
  background-color: #42ffff;
  color: #ffffff;
}
.banner-section .banner-subcontainer .btn-icon {
  width: 5rem;
  position: absolute;
  right: -20%;
  top: 1rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .banner-section {
    padding-bottom: 10rem;
    flex-direction: column;
    row-gap: 2rem;
    margin-top: 2rem !important;
  }
  .banner-section .online-pay-mobile-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .banner-section .online-pay-mobile-btn-container .online-pay-mobile-btn {
    background-color: #5ec502;
    padding: 0.25rem 1.5rem;
    border-radius: 1rem;
    font-weight: bold;
  }
  .banner-section .online-pay-mobile-btn-container .online-pay-mobile-btn:link {
    color: #ffffff;
    text-decoration: none;
  }
  .banner-section .online-pay-mobile-btn-container .online-pay-mobile-btn:visited {
    color: #ffffff;
  }
  .banner-section .online-pay-mobile-btn-container .online-pay-mobile-btn:active {
    color: #ffffff;
  }
  .banner-section .online-pay-mobile-btn-container .online-pay-mobile-btn:hover {
    color: #5ec502;
    border: 1px solid #5ec502;
    background-color: #ffffff;
  }
  .banner-section .banner-subcontainer {
    grid-template-columns: 1fr;
  }
  .banner-section .banner-subcontainer .banner-info-container {
    align-items: center;
  }
  .banner-section .banner-subcontainer .main-img {
    width: 100%;
  }
  .banner-section .banner-subcontainer .banner-btn-info-container {
    width: unset;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .banner-section {
    padding-bottom: 10rem;
    flex-direction: column;
    row-gap: 2rem;
    margin-top: 2rem !important;
  }
  .banner-section .online-pay-mobile-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .banner-section .online-pay-mobile-btn-container .online-pay-mobile-btn {
    background-color: #5ec502;
    padding: 0.25rem 1.5rem;
    border-radius: 1rem;
    font-weight: bold;
  }
  .banner-section .online-pay-mobile-btn-container .online-pay-mobile-btn:link {
    color: #ffffff;
    text-decoration: none;
  }
  .banner-section .online-pay-mobile-btn-container .online-pay-mobile-btn:visited {
    color: #ffffff;
  }
  .banner-section .online-pay-mobile-btn-container .online-pay-mobile-btn:active {
    color: #ffffff;
  }
  .banner-section .online-pay-mobile-btn-container .online-pay-mobile-btn:hover {
    color: #5ec502;
    border: 1px solid #5ec502;
    background-color: #ffffff;
  }
  .banner-section .banner-subcontainer {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .banner-section .banner-subcontainer .banner-info-container {
    align-items: center;
  }
  .banner-section .banner-subcontainer .main-img {
    width: 100%;
    height: 80%;
  }
  .banner-section .banner-subcontainer .banner-btn-info-container {
    width: unset;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .banner-section {
    padding-bottom: 10rem;
    flex-direction: column;
    row-gap: 2rem;
  }
  .banner-section .online-pay-mobile-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .banner-section .online-pay-mobile-btn-container .online-pay-mobile-btn {
    background-color: #5ec502;
    padding: 0.25rem 1.5rem;
    border-radius: 1rem;
    font-weight: bold;
  }
  .banner-section .online-pay-mobile-btn-container .online-pay-mobile-btn:link {
    color: #ffffff;
    text-decoration: none;
  }
  .banner-section .online-pay-mobile-btn-container .online-pay-mobile-btn:visited {
    color: #ffffff;
  }
  .banner-section .online-pay-mobile-btn-container .online-pay-mobile-btn:active {
    color: #ffffff;
  }
  .banner-section .online-pay-mobile-btn-container .online-pay-mobile-btn:hover {
    color: #5ec502;
    border: 1px solid #5ec502;
    background-color: #ffffff;
  }
  .banner-section .banner-subcontainer {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .banner-section .banner-subcontainer .banner-info-container {
    align-items: center;
  }
  .banner-section .banner-subcontainer .banner-btn-info-container {
    width: unset;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .online-pay-mobile-btn-container {
    display: none;
  }
  .banner-subcontainer {
    align-items: flex-end !important;
  }
  .banner-info-container {
    transform: scale(0.75);
    position: relative;
    top: -4rem;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .online-pay-mobile-btn-container {
    display: none;
  }
  .banner-info-container {
    transform: scale(0.8);
    position: relative;
    top: -4rem;
  }
  .btn-icon {
    right: -10% !important;
  }
}
@media screen and (min-width: 1920px) {
  .online-pay-mobile-btn-container {
    display: none;
  }
  .btn-icon {
    right: -10% !important;
  }
}/*# sourceMappingURL=BannerSection.css.map */