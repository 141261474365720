@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
/* @font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}

@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}

@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}

$astralSistersFont: AstralSisters;
$clanProBlkFont: ClanProBlk;
$clanProBoldFont: ClanProBold;
$clanProMediuFont: ClanProMedium;
$clanProRegularFont: ClanProRegular;
$clanProRgNewsFont: ClanProRgNews;
$clanProUltraFont: ClanProUltra;
$sarabunBoldFont: SarabunBold;
$sarabunExtraBoldFont: SarabunExtraBold;
$sarabunLightFont: SarabunLight;
$sarabunLightItalicFont: SarabunLightItalic;
$sarabunMediumFont: SarabunMedium;
$sarabunRegularFont: SarabunRegular;
$sarabunSemiBoldFont: SarabunSemiBold;
$playFairFont: "Playfair Display", serif; */
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.contact-navbar-section {
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 1rem;
}
.contact-navbar-section .contact-navbar-subcontainer {
  width: 1024px;
  display: flex;
  justify-content: flex-end;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.contact-navbar-section .contact-navbar-subcontainer .contact-container {
  display: flex;
  align-items: center;
  -moz-column-gap: 0.2rem;
       column-gap: 0.2rem;
  color: #7a7a7a;
  font-size: 0.8rem;
}
.contact-navbar-section .contact-navbar-subcontainer .btn-cn {
  color: #7a7a7a;
  text-decoration: none;
}
.contact-navbar-section .contact-navbar-subcontainer .btn-cn:hover {
  border-bottom: 2px solid #014da1;
  color: #014da1;
}
.contact-navbar-section .contact-navbar-subcontainer .contact-icon {
  width: 1rem;
  height: 1rem;
}
.contact-navbar-section .contact-navbar-subcontainer .social-media-container {
  display: flex;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.contact-navbar-section .contact-navbar-subcontainer .social-media-icon {
  width: 1.5rem;
  height: 1.5rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .contact-navbar-section {
    padding-right: 0rem;
  }
  .contact-navbar-section .contact-navbar-subcontainer {
    -moz-column-gap: 0;
         column-gap: 0;
  }
  .contact-navbar-section .contacts-container-mobile {
    display: flex;
    align-items: center;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
    color: #7a7a7a;
    width: 100%;
    justify-content: center;
  }
  .contact-navbar-section .contacts-container-mobile .contact-subcontainer-mobile {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
  }
  .contact-navbar-section .contact-container {
    display: none !important;
  }
  .contact-navbar-section .social-media-container {
    display: none !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .contact-navbar-section {
    padding-right: 0rem;
  }
  .contact-navbar-section .contact-navbar-subcontainer {
    -moz-column-gap: 0;
         column-gap: 0;
  }
  .contact-navbar-section .contacts-container-mobile {
    display: flex;
    align-items: center;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
    color: #7a7a7a;
    width: 100%;
    justify-content: center;
  }
  .contact-navbar-section .contacts-container-mobile .contact-subcontainer-mobile {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
  }
  .contact-navbar-section .contact-container {
    display: none !important;
  }
  .contact-navbar-section .social-media-container {
    display: none !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .contact-navbar-section {
    padding-right: 0rem;
  }
  .contact-navbar-section .contact-navbar-subcontainer {
    -moz-column-gap: 0;
         column-gap: 0;
  }
  .contact-navbar-section .contacts-container-mobile {
    display: flex;
    align-items: center;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
    color: #7a7a7a;
    width: 100%;
    justify-content: center;
  }
  .contact-navbar-section .contacts-container-mobile .contact-subcontainer-mobile {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
  }
  .contact-navbar-section .contact-container {
    display: none !important;
  }
  .contact-navbar-section .social-media-container {
    display: none !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .contact-navbar-subcontainer {
    margin: 0 1rem;
  }
  .contacts-container-mobile {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .contacts-container-mobile {
    display: none;
  }
}/*# sourceMappingURL=ContactNavbar.css.map */