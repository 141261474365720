@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
/* @font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}

@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}

@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}

$astralSistersFont: AstralSisters;
$clanProBlkFont: ClanProBlk;
$clanProBoldFont: ClanProBold;
$clanProMediuFont: ClanProMedium;
$clanProRegularFont: ClanProRegular;
$clanProRgNewsFont: ClanProRgNews;
$clanProUltraFont: ClanProUltra;
$sarabunBoldFont: SarabunBold;
$sarabunExtraBoldFont: SarabunExtraBold;
$sarabunLightFont: SarabunLight;
$sarabunLightItalicFont: SarabunLightItalic;
$sarabunMediumFont: SarabunMedium;
$sarabunRegularFont: SarabunRegular;
$sarabunSemiBoldFont: SarabunSemiBold;
$playFairFont: "Playfair Display", serif; */
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.popup-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8549019608);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-container .popup-subcontainer {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #014da1;
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 0 1rem;
  padding-bottom: 1.5rem;
}
.popup-container .popup-subcontainer .popup-header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #014da1;
  border-top: 1px solid #014da1;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  overflow: hidden;
  padding: 0.5rem 1rem;
}
.popup-container .popup-subcontainer .ph-title {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: bold;
}
.popup-container .popup-subcontainer .ph-close-icon-container {
  width: 1rem;
  height: 1rem;
}
.popup-container .popup-subcontainer .ph-close-icon {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  cursor: pointer;
}
.popup-container .popup-subcontainer .popup-body-container {
  width: 95%;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.popup-container .popup-subcontainer .pb-subtitle {
  text-align: center;
  color: #014da1;
  font-weight: 300;
  font-size: 1.25rem;
}
.popup-container .popup-subcontainer .pb-authorized-icon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem;
}
.popup-container .popup-subcontainer .pb-authorized-icon {
  width: 6rem;
  height: auto;
}
.popup-container .popup-subcontainer .deco-img-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-container .popup-subcontainer .pb-deco-img {
  width: 8rem;
  height: auto;
}
.popup-container .popup-subcontainer .pb-description-txt {
  text-align: center;
  color: #014da1;
  font-weight: 300;
  font-size: 1.25rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .popup-subcontainer {
    width: 80% !important;
  }
  .popup-subcontainer .ph-title {
    font-size: 1.25rem !important;
  }
  .popup-subcontainer .pb-subtitle {
    font-size: 1rem !important;
  }
  .popup-subcontainer .pb-authorized-icon-container {
    margin: 0 !important;
  }
  .popup-subcontainer .pb-authorized-icon {
    width: 4rem !important;
  }
  .popup-subcontainer .pb-deco-img {
    width: 5rem !important;
  }
  .popup-subcontainer .pb-description-txt {
    font-size: 1rem !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .popup-subcontainer {
    width: 80% !important;
  }
  .popup-subcontainer .ph-title {
    font-size: 1.25rem !important;
  }
  .popup-subcontainer .pb-subtitle {
    font-size: 1rem !important;
  }
  .popup-subcontainer .pb-authorized-icon-container {
    margin: 0 !important;
  }
  .popup-subcontainer .pb-authorized-icon {
    width: 4rem !important;
  }
  .popup-subcontainer .pb-deco-img {
    width: 5rem !important;
  }
  .popup-subcontainer .pb-description-txt {
    font-size: 1rem !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .popup-subcontainer {
    width: 60% !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .popup-subcontainer {
    width: 60% !important;
  }
}/*# sourceMappingURL=PopupComponent.css.map */