@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
/* @font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}

@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}

@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}

$astralSistersFont: AstralSisters;
$clanProBlkFont: ClanProBlk;
$clanProBoldFont: ClanProBold;
$clanProMediuFont: ClanProMedium;
$clanProRegularFont: ClanProRegular;
$clanProRgNewsFont: ClanProRgNews;
$clanProUltraFont: ClanProUltra;
$sarabunBoldFont: SarabunBold;
$sarabunExtraBoldFont: SarabunExtraBold;
$sarabunLightFont: SarabunLight;
$sarabunLightItalicFont: SarabunLightItalic;
$sarabunMediumFont: SarabunMedium;
$sarabunRegularFont: SarabunRegular;
$sarabunSemiBoldFont: SarabunSemiBold;
$playFairFont: "Playfair Display", serif; */
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.counter-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0rem 0;
  position: relative;
  background-color: #ffffff;
  padding-bottom: 1rem;
}
.counter-section .bg-skew {
  height: 7rem;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  z-index: 0;
}
.counter-section .top {
  top: -6.5rem;
  -webkit-clip-path: polygon(0 89%, 100% 14%, 100% 100%, 0 100%);
          clip-path: polygon(0 89%, 100% 14%, 100% 100%, 0 100%);
}
.counter-section .bottom {
  top: 25rem;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 88%, 0 14%);
          clip-path: polygon(0% 0%, 100% 0%, 100% 88%, 0 14%);
}
.counter-section .counter-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5rem;
  z-index: 1;
}
.counter-section .counter-subcontainer .counter-title {
  font-weight: 500;
  color: #014da1;
  font-size: 2rem;
}
.counter-section .counter-subcontainer .counter-info-container {
  display: flex;
  justify-content: center;
  align-items: baseline;
  -moz-column-gap: 5rem;
       column-gap: 5rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .counter-section .supercontainer {
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
  .counter-section .counter-info-container {
    display: none !important;
  }
  .counter-section .counter-info-container-mobile {
    height: 18rem;
  }
  .counter-section .counter-info-container-tablet {
    display: none;
  }
  .counter-section .bottom {
    top: 26rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .counter-section .supercontainer {
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
  .counter-section .counter-info-container {
    display: none !important;
  }
  .counter-section .counter-info-container-tablet {
    display: none;
  }
  .counter-section .counter-info-container-mobile {
    height: 18rem;
  }
  .counter-section .bottom {
    top: 26rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .counter-section .supercontainer {
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
  .counter-section .counter-info-container {
    display: none !important;
  }
  .counter-section .counter-info-container-mobile {
    display: none;
  }
  .counter-section .bottom {
    top: 25rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .counter-section .supercontainer {
    overflow: hidden;
    display: flex;
    justify-content: center;
    margin: 0 1rem;
  }
  .counter-section .counter-info-container-mobile {
    display: none;
  }
  .counter-section .counter-info-container-tablet {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .counter-section .bg-skew {
    height: 8rem;
  }
  .counter-section .top {
    top: -7.5rem;
  }
  .counter-section .supercontainer {
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
  .counter-section .counter-info-container-mobile {
    display: none;
  }
  .counter-section .counter-info-container-tablet {
    display: none;
  }
}/*# sourceMappingURL=CounterSection.css.map */