@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
/* @font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}

@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}

@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}

$astralSistersFont: AstralSisters;
$clanProBlkFont: ClanProBlk;
$clanProBoldFont: ClanProBold;
$clanProMediuFont: ClanProMedium;
$clanProRegularFont: ClanProRegular;
$clanProRgNewsFont: ClanProRgNews;
$clanProUltraFont: ClanProUltra;
$sarabunBoldFont: SarabunBold;
$sarabunExtraBoldFont: SarabunExtraBold;
$sarabunLightFont: SarabunLight;
$sarabunLightItalicFont: SarabunLightItalic;
$sarabunMediumFont: SarabunMedium;
$sarabunRegularFont: SarabunRegular;
$sarabunSemiBoldFont: SarabunSemiBold;
$playFairFont: "Playfair Display", serif; */
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.financial-process-section {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5rem 0;
  background-color: #ffffff;
  position: relative;
}
.financial-process-section .bg-skew {
  height: 7rem;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  z-index: 0;
}
.financial-process-section .bottom {
  top: 50rem;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 24%, 0% 75%);
          clip-path: polygon(0% 0%, 100% 0%, 100% 24%, 0% 75%);
}
.financial-process-section .financial-process-subcontainer {
  width: 1024px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  row-gap: 5rem;
}
.financial-process-section .financial-process-subcontainer .fp-titles {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.financial-process-section .financial-process-subcontainer .fp-title {
  color: #014da1;
  font-weight: 500;
  font-size: 2rem;
  text-align: center;
}
.financial-process-section .financial-process-subcontainer .fp-subtitle {
  margin-left: 20%;
  color: #7a7a7a;
  font-weight: 300;
  font-size: 1rem;
}
.financial-process-section .financial-process-subcontainer .fp-steps-container-mobile {
  display: flex;
  justify-content: center;
  width: 40%;
  height: 25rem;
  margin: 0 1rem;
}
.financial-process-section .financial-process-subcontainer .fp-steps-container {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: baseline;
}
.financial-process-section .financial-process-subcontainer .step-img {
  width: 10rem;
  height: 100%;
}
.financial-process-section .financial-process-subcontainer .step-number {
  border: 2px solid #42ffff;
  border-radius: 1rem;
  padding: 0.25rem 1rem;
  color: #014da1;
}
.financial-process-section .financial-process-subcontainer .step-text-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
}
.financial-process-section .financial-process-subcontainer .step-subtitle {
  color: #014da1;
  font-size: 0.9rem;
}
.financial-process-section .financial-process-subcontainer .step-content-text {
  color: #7a7a7a;
  font-size: 0.9rem;
}
.financial-process-section .financial-process-subcontainer .next-icon {
  width: 0.9rem;
  height: 100%;
  margin: 0 0.5rem;
}
.financial-process-section .financial-process-subcontainer .step-container {
  display: flex;
  align-items: baseline;
}
.financial-process-section .financial-process-subcontainer .step-info-container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  align-items: center;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .financial-process-section .fp-titles {
    align-items: center;
    row-gap: 1rem;
  }
  .financial-process-section .fp-titles .fp-title {
    font-size: 1.2rem !important;
  }
  .financial-process-section .fp-titles .fp-subtitle {
    width: 40%;
    margin-left: 0;
  }
  .financial-process-section .fp-steps-container-tablet {
    display: none;
  }
  .financial-process-section .fp-steps-container {
    display: none !important;
  }
  .financial-process-section .fp-btn-container {
    display: flex;
    justify-content: center;
  }
  .financial-process-section .bottom {
    top: 52rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .financial-process-section .fp-titles {
    align-items: center;
    row-gap: 1rem;
  }
  .financial-process-section .fp-titles .fp-title {
    font-size: 1.2rem !important;
  }
  .financial-process-section .fp-titles .fp-subtitle {
    width: 40%;
    margin-left: 0;
  }
  .financial-process-section .fp-steps-container-tablet {
    display: none;
  }
  .financial-process-section .fp-steps-container {
    display: none !important;
  }
  .financial-process-section .fp-btn-container {
    display: flex;
    justify-content: center;
  }
  .financial-process-section .bottom {
    top: 52rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .financial-process-section .fp-steps-container-mobile {
    display: none !important;
  }
  .financial-process-section .fp-steps-container-tablet {
    width: 90%;
  }
  .financial-process-section .fp-steps-container {
    display: none !important;
  }
  .financial-process-section .fp-btn-container {
    display: flex;
    justify-content: center;
  }
  .financial-process-section .bottom {
    top: 47rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .financial-process-section .financial-process-subcontainer {
    margin: 0 1rem;
  }
  .financial-process-section .fp-steps-container-mobile {
    display: none !important;
  }
  .financial-process-section .fp-steps-container-tablet {
    display: none;
  }
  .financial-process-section .fp-btn-container {
    display: flex;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .financial-process-section .fp-steps-container-mobile {
    display: none !important;
  }
  .financial-process-section .fp-steps-container-tablet {
    display: none;
  }
  .financial-process-section .fp-btn-container {
    display: flex;
    justify-content: center;
  }
}/*# sourceMappingURL=FinancialProcessSection.css.map */