@import "../../sass/variables";

.work-with-us-section {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5rem 0 3rem 0;
  .wwu-subcontainer {
    width: 90%;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    column-gap: 3rem;
    .wwu-deco-img {
      width: 100%;
    }
    .wwu-form-container {
      width: 70%;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .work-with-us-section {
    padding-bottom: 10rem;
  }
  .wwu-subcontainer {
    grid-template-columns: none !important;
    .wwu-form-container {
      width: 100% !important;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .work-with-us-section {
    padding-bottom: 10rem;
  }
  .wwu-subcontainer {
    grid-template-columns: none !important;
    .wwu-form-container {
      width: 100% !important;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .work-with-us-section {
    padding-bottom: 10rem;
  }
  .wwu-subcontainer {
    grid-template-columns: none !important;
    width: 70% !important;
    .wwu-form-container {
      width: 100% !important;
    }
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
  .work-with-us-section {
    padding-bottom: 10rem;
  }
  .wwu-form-container {
    width: 100% !important;
  }
}

//Responsive Screen Small
@media screen and (min-width: $screenSm) and (max-width: calc($screenHd - 1px)) {
  .work-with-us-section {
    padding: 0;
  }
  .wwu-form-container {
    width: 100% !important;
  }
}

//Responsive Screen HD >
@media screen and (min-width: $screenHd) {
  .work-with-us-section {
    padding: 0;
  }
}