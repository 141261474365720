@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
/* @font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}

@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}

@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}

$astralSistersFont: AstralSisters;
$clanProBlkFont: ClanProBlk;
$clanProBoldFont: ClanProBold;
$clanProMediuFont: ClanProMedium;
$clanProRegularFont: ClanProRegular;
$clanProRgNewsFont: ClanProRgNews;
$clanProUltraFont: ClanProUltra;
$sarabunBoldFont: SarabunBold;
$sarabunExtraBoldFont: SarabunExtraBold;
$sarabunLightFont: SarabunLight;
$sarabunLightItalicFont: SarabunLightItalic;
$sarabunMediumFont: SarabunMedium;
$sarabunRegularFont: SarabunRegular;
$sarabunSemiBoldFont: SarabunSemiBold;
$playFairFont: "Playfair Display", serif; */
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.job-offer-form-container {
  width: 100%;
  position: absolute;
  top: 10rem;
  display: flex;
  justify-content: center;
}
.job-offer-form-container .job-offer-form-subcontainer {
  width: 1024px;
  display: flex;
  justify-content: flex-end;
}
.job-offer-form-container .job-offer-form-subcontainer .jof-form-container {
  width: 20rem;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 1rem;
}
.job-offer-form-container .job-offer-form-subcontainer .jof-form-subcontainer {
  margin: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.job-offer-form-container .job-offer-form-subcontainer .jof-form-title {
  color: #014da1;
  font-weight: 400;
  font-size: 1.75rem;
}
.job-offer-form-container .job-offer-form-subcontainer .jof-form-label {
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  color: #001d3c;
}
.job-offer-form-container .job-offer-form-subcontainer .jof-form-input {
  border: 1px solid #014da1;
  resize: none;
  padding: 0.5rem 1rem;
  outline: none;
  border-radius: 0.5rem;
}
.job-offer-form-container .job-offer-form-subcontainer .error-form {
  border: 1px solid red;
}
.job-offer-form-container .job-offer-form-subcontainer .jof-form-btn {
  padding: 0.5rem 1.5rem;
  background-color: #014da1;
  border: 1px solid #014da1;
  color: #ffffff;
  border-radius: 0.5rem;
  outline: none;
  cursor: pointer;
}
.job-offer-form-container .job-offer-form-subcontainer .jof-form-btn:hover {
  background-color: #ffffff;
  color: #014da1;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .job-offer-form-container {
    position: unset !important;
  }
  .job-offer-form-container .job-offer-form-subcontainer {
    width: 90% !important;
    justify-content: center !important;
  }
  .job-offer-form-container .jof-form-container {
    width: 100% !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .job-offer-form-container {
    position: unset !important;
  }
  .job-offer-form-container .job-offer-form-subcontainer {
    width: 80% !important;
    justify-content: center !important;
  }
  .job-offer-form-container .jof-form-container {
    width: 100% !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .job-offer-form-container {
    position: unset !important;
  }
  .job-offer-form-container .job-offer-form-subcontainer {
    width: 90% !important;
    justify-content: center !important;
  }
  .job-offer-form-container .jof-form-container {
    width: 50% !important;
  }
}/*# sourceMappingURL=JobOfferFormComponent.css.map */