@import "../../sass/variables";

.job-offer-grid-section {
  width: 100%;
  display: flex;
  justify-content: center;
  .job-offer-grid-subcontainer {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4rem;
    row-gap: 2rem;
    .jog-title {
      color: darken($navbarMenuColorPrimary, $amount: 10);
      font-size: 2rem;
      font-weight: 500;
    }
    .jog-content-container {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      width: 100%;
    }
    .jog-c-filter-container {
      display: flex;
      justify-content: center;
      column-gap: 3rem;
    }
    .jog-c-f-search-container {
      border: 1px solid $mainColorSecondary;
      border-radius: 1rem;
      overflow: hidden;
      background-color: $basicColorPrimary;
    }
    .jog-c-f-s-input {
      padding: 0.5rem 0 0.25rem 1rem;
      border-color: transparent;
      outline: none;
      width: 15rem;
      color: $mainColorSecondary;
    }
    .jog-c-f-s-search-btn {
      height: 100%;
      margin-right: 0.5rem;
      background-color: $mainColorSecondary;
      border-radius: 100%;
      border-color: $mainColorSecondary;
      outline: none;
      cursor: pointer;
      &:hover {
        background-color: $basicColorPrimary;
      }
    }
    .jog-c-f-s-sb-icon {
      width: 1rem;
      height: auto;
    }
    .jog-c-f-select {
      border: 1px solid $mainColorSecondary;
      height: 100%;
      border-radius: 1rem;
      padding: 0 1rem;
      color: $mainColorSecondary;
      outline: none;
    }
    .jog-c-grid-container {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2rem;
      row-gap: 2rem;
    }
    .jog-c-g-card-container {
      border: 1px solid $mainColorSecondary;
      padding: 1rem 2rem;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .jog-c-g-card-title {
      font-weight: 500;
      color: $navbarMenuColorPrimary;
    }
    .jog-c-g-card-type-container {
      display: flex;
      align-items: flex-start;
      column-gap: 0.5rem;
      color: $mainColorSecondary;
    }
    .jog-c-g-ct-icon {
      width: auto;
      height: 1.5rem;
    }
    .jog-c-g-btn-elm-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .jog-c-g-be-btn {
      background-color: $mainColorSecondary;
      border: 1px solid $mainColorSecondary;
      color: $basicColorPrimary;
      border-radius: 1rem;
      font-size: 0.8rem;
      padding: 0.25rem 0.5rem;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        background-color: $basicColorPrimary;
        color: $mainColorSecondary;
      }
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .job-offer-grid-subcontainer {
    width: 90% !important;
    .jog-title {
      font-size: 1.5rem !important;
      text-align: center;
    }
    .jog-c-filter-container {
      flex-direction: column;
      row-gap: 1rem;
      .jog-c-f-select {
        width: 100%;
        padding: 0.5rem 1rem !important;
      }
      .jog-c-f-search-container {
        position: relative;
      }
      .jog-c-f-s-search-btn {
        position: absolute;
        right: 5%;
      }
    }
    .jog-c-grid-container {
      grid-template-columns: 1fr !important;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .job-offer-grid-subcontainer {
    width: 80% !important;
    .jog-title {
      font-size: 1.5rem !important;
      text-align: center;
    }
    .jog-c-filter-container {
      flex-direction: column;
      row-gap: 1rem;
      .jog-c-f-select {
        width: 100%;
        padding: 0.5rem 1rem !important;
      }
      .jog-c-f-search-container {
        position: relative;
      }
      .jog-c-f-s-search-btn {
        position: absolute;
        right: 5%;
      }
    }
    .jog-c-grid-container {
      grid-template-columns: 1fr !important;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .job-offer-grid-subcontainer {
    width: 90% !important;
    .jog-title {
      text-align: center;
    }
    .jog-c-f-select {
      width: 100%;
      padding: 0.5rem 1rem !important;
    }
    .jog-c-f-search-container {
      position: relative;
    }
    .jog-c-f-s-search-btn {
      position: absolute;
      right: 5%;
    }
    .jog-c-grid-container {
      grid-template-columns: 1fr 1fr !important;
    }
  }
}
