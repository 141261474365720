@import "../../sass/variables";

.wa-btn-container {
  width: 10rem;
  height: 4rem;
  display: flex;
  position: fixed;
  bottom: 2rem;
  left: 1rem;
  z-index: 100;
  align-items: center;
  .wa-btn-icon-container {
    width: 3.5rem;
    .wa-icon {
      width: 100%;
      height: 100%;
    }
  }
  .wa-hover-element {
    width: 70%;
    padding: 0.2rem 1rem;
    background-color: $whatsappBtnColor;
    color: $basicColorPrimary;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    position: absolute;
    left: 40%;
    z-index: -1;
    opacity: 0;
  }
  .show {
    @keyframes showWATitle {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    animation-name: showWATitle;
    animation-duration: 1s;
    opacity: 1;
  }
  .hide {
    @keyframes hide {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    animation-name: hide;
    animation-duration: 1s;
    opacity: 0;
  }
}

/*Responsive Mobile Small*/

@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .wa-btn-container {
    bottom: 1rem;
    .wa-btn-icon-container {
      width: 3rem !important;
    }
    .wa-hover-element {
      left: 35%;
    }
  }
}
