@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
/* @font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}

@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}

@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}

$astralSistersFont: AstralSisters;
$clanProBlkFont: ClanProBlk;
$clanProBoldFont: ClanProBold;
$clanProMediuFont: ClanProMedium;
$clanProRegularFont: ClanProRegular;
$clanProRgNewsFont: ClanProRgNews;
$clanProUltraFont: ClanProUltra;
$sarabunBoldFont: SarabunBold;
$sarabunExtraBoldFont: SarabunExtraBold;
$sarabunLightFont: SarabunLight;
$sarabunLightItalicFont: SarabunLightItalic;
$sarabunMediumFont: SarabunMedium;
$sarabunRegularFont: SarabunRegular;
$sarabunSemiBoldFont: SarabunSemiBold;
$playFairFont: "Playfair Display", serif; */
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.salesforce-bg-container {
  width: 100vw;
  height: 100vh;
}

.sb-img {
  width: 100%;
  height: 100%;
}/*# sourceMappingURL=SalesforceBgScreen.css.map */