@import "../../sass/variables";

.loading-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: $basicColorPrimaryOpa;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .loading-logo {
    width: 15rem;
    @keyframes animationLogo {
      0% {
        transform: rotateY(0deg);
      }
      50% {
        transform: rotateY(180deg);
      }
      100% {
        transform: rotateY(360deg);
      }
    }
    animation-name: animationLogo;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
}
