@import "../../sass/variables";

.job-offer-info-section {
  width: 100%;
  display: flex;
  justify-content: center;
  .job-offer-info-subcontainer {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 4rem 0;
    row-gap: 2rem;
    .joi-skill-container {
      width: 65%;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      color: $navbarMenuColorPrimary;
    }
    .joi-job-info-container {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .joi-ji-grid {
      display: flex;
      align-items: center;
      color: $navbarMenuColorPrimary;
      column-gap: 5rem;
    }
    .joi-skill-title {
      color: $mainColorSecondary;
      font-weight: 500;
      font-size: 2rem;
    }
    .joi-skill-elm-container {
      display: flex;
      align-items: center;
      column-gap: 1rem;
      flex-wrap: wrap;
    }
    .joi-se-elm {
      color: $navbarMenuColorPrimary;
      border: 1px solid $navbarMenuColorPrimary;
      font-size: 1rem;
      border-radius: 0.5rem;
      padding: 0.25rem 1rem;
      text-align: center;
      font-weight: 400;
    }
    .joi-description-container {
      width: 65%;
      color: $navbarMenuColorPrimary;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .joi-func-container {
      width: 65%;
      color: $navbarMenuColorPrimary;
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
    }
    .joi-benefits-container {
      width: 65%;
      color: $navbarMenuColorPrimary;
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
    }
    .joi-requirements-container {
      width: 65%;
      color: $navbarMenuColorPrimary;
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
    }
    .joi-skills-container {
      width: 65%;
      color: $navbarMenuColorPrimary;
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .job-offer-info-subcontainer {
    width: 90% !important;
    align-items: center !important;
    .joi-skill-elm-container {
      row-gap: 1rem;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .job-offer-info-subcontainer {
    width: 80% !important;
    align-items: center !important;
    .joi-skill-elm-container {
      row-gap: 1rem;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .job-offer-info-subcontainer {
    width: 90% !important;
    align-items: center !important;
    .joi-skill-elm-container {
      row-gap: 1rem;
    }
  }
}
