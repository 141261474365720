@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
/* @font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}

@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}

@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}

$astralSistersFont: AstralSisters;
$clanProBlkFont: ClanProBlk;
$clanProBoldFont: ClanProBold;
$clanProMediuFont: ClanProMedium;
$clanProRegularFont: ClanProRegular;
$clanProRgNewsFont: ClanProRgNews;
$clanProUltraFont: ClanProUltra;
$sarabunBoldFont: SarabunBold;
$sarabunExtraBoldFont: SarabunExtraBold;
$sarabunLightFont: SarabunLight;
$sarabunLightItalicFont: SarabunLightItalic;
$sarabunMediumFont: SarabunMedium;
$sarabunRegularFont: SarabunRegular;
$sarabunSemiBoldFont: SarabunSemiBold;
$playFairFont: "Playfair Display", serif; */
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.work-with-us-section {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5rem 0 3rem 0;
}
.work-with-us-section .wwu-subcontainer {
  width: 90%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  -moz-column-gap: 3rem;
       column-gap: 3rem;
}
.work-with-us-section .wwu-subcontainer .wwu-deco-img {
  width: 100%;
}
.work-with-us-section .wwu-subcontainer .wwu-form-container {
  width: 70%;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .work-with-us-section {
    padding-bottom: 10rem;
  }
  .wwu-subcontainer {
    grid-template-columns: none !important;
  }
  .wwu-subcontainer .wwu-form-container {
    width: 100% !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .work-with-us-section {
    padding-bottom: 10rem;
  }
  .wwu-subcontainer {
    grid-template-columns: none !important;
  }
  .wwu-subcontainer .wwu-form-container {
    width: 100% !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .work-with-us-section {
    padding-bottom: 10rem;
  }
  .wwu-subcontainer {
    grid-template-columns: none !important;
    width: 70% !important;
  }
  .wwu-subcontainer .wwu-form-container {
    width: 100% !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .work-with-us-section {
    padding-bottom: 10rem;
  }
  .wwu-form-container {
    width: 100% !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .work-with-us-section {
    padding: 0;
  }
  .wwu-form-container {
    width: 100% !important;
  }
}
@media screen and (min-width: 1920px) {
  .work-with-us-section {
    padding: 0;
  }
}/*# sourceMappingURL=BodyWorkFormScreen.css.map */