@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
/* @font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}

@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}

@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}

$astralSistersFont: AstralSisters;
$clanProBlkFont: ClanProBlk;
$clanProBoldFont: ClanProBold;
$clanProMediuFont: ClanProMedium;
$clanProRegularFont: ClanProRegular;
$clanProRgNewsFont: ClanProRgNews;
$clanProUltraFont: ClanProUltra;
$sarabunBoldFont: SarabunBold;
$sarabunExtraBoldFont: SarabunExtraBold;
$sarabunLightFont: SarabunLight;
$sarabunLightItalicFont: SarabunLightItalic;
$sarabunMediumFont: SarabunMedium;
$sarabunRegularFont: SarabunRegular;
$sarabunSemiBoldFont: SarabunSemiBold;
$playFairFont: "Playfair Display", serif; */
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.navbar-container {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 4rem;
  background-color: #e2e2e2;
  align-items: center;
  z-index: 3;
}
.navbar-container .navbar-subcontainer {
  width: 1024px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.navbar-container .navbar-subcontainer .logo-img {
  width: 10rem;
  height: 100%;
}
.navbar-container .navbar-subcontainer .options-container {
  display: flex;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  align-items: center;
}
.navbar-container .navbar-subcontainer .option {
  color: #000000;
  text-decoration: none;
  font-weight: bold;
  color: #7a7a7a;
  font-size: 0.85rem;
}
.navbar-container .navbar-subcontainer .option:hover {
  border-bottom: 2px solid #014da1;
  padding-bottom: 0.25rem;
  color: #014da1;
}
.navbar-container .navbar-subcontainer .pay-btn {
  border: 1px solid #5ec502;
  padding: 0.25rem 1rem;
  border-radius: 1rem;
  background-color: #5ec502;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-container .navbar-subcontainer .pay-btn:hover {
  background-color: #ffffff;
  border-bottom: 1px solid #5ec502;
  color: #5ec502;
}
.navbar-container .navbar-subcontainer .nb-mobile {
  display: none;
}
.navbar-container .menu-options-container {
  position: absolute;
  top: 3rem;
  width: 100%;
  background-color: #ffffff;
  left: -40rem;
}
.navbar-container .menu-options-container .anchor-container {
  padding: 1rem 0 1rem 2rem;
}
.navbar-container .menu-options-container .anchor-container:hover {
  color: #ffffff;
  background-color: #55595C;
}
.navbar-container .menu-options-container .anchor-container:hover .anchor {
  color: #ffffff;
}
.navbar-container .menu-options-container .anchor {
  color: #000000;
}
.navbar-container .menu-options-container .anchor:link {
  color: #000000;
  text-decoration: none;
}
.navbar-container .menu-options-container .anchor:visited {
  color: #000000;
}
.navbar-container .menu-options-container .anchor:active {
  color: #000000;
}
.navbar-container .open-menu {
  animation-name: openMenuNv;
  animation-duration: 1s;
  left: 0rem;
}
@keyframes openMenuNv {
  0% {
    left: -70rem;
  }
  100% {
    left: 0rem;
  }
}
.navbar-container .close-menu {
  animation-name: closeMenuNv;
  animation-duration: 1s;
  left: -70rem;
}
@keyframes closeMenuNv {
  0% {
    left: 0rem;
  }
  100% {
    left: -70rem;
  }
}
.navbar-container .ham-menu-icon {
  width: 1.5rem;
  height: 100%;
}

.fixed-nb {
  position: fixed;
  top: 0;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .navbar-container .logo-img {
    width: 7rem !important;
    margin-left: 1rem;
  }
  .navbar-container .ham-menu-container {
    margin-right: 1rem;
  }
  .navbar-container .options-container {
    display: none !important;
  }
  .navbar-container .nb-mobile {
    display: unset !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .navbar-container .logo-img {
    width: 7rem !important;
    margin-left: 1rem;
  }
  .navbar-container .ham-menu-container {
    margin-right: 1rem;
  }
  .navbar-container .options-container {
    display: none !important;
  }
  .navbar-container .nb-pay-btn-container {
    position: absolute;
    right: 15%;
  }
  .navbar-container .nb-pay-btn-container .nb-mobile {
    display: unset !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .navbar-container .logo-container {
    margin-left: 10%;
  }
  .navbar-container .ham-menu-container {
    margin-right: 10% !important;
  }
  .navbar-container .ham-menu-container {
    margin-right: 1rem;
  }
  .navbar-container .options-container {
    display: none !important;
  }
  .navbar-container .nb-pay-btn-container {
    position: absolute;
    right: 18%;
  }
  .navbar-container .nb-pay-btn-container .nb-mobile {
    display: unset !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .navbar-subcontainer {
    margin: 0 1rem;
  }
  .menu-options-container {
    display: none;
  }
  .ham-menu-container {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .ham-menu-container {
    display: none;
  }
  .menu-options-container {
    display: none !important;
  }
}/*# sourceMappingURL=Navbar.css.map */