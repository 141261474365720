@import '../../sass/variables';

.btn {
    width: 100%;
    border: 3px solid $btnColorSecondary;
    padding: 0.3rem 2rem;
    border-radius: 1.5rem;
    background-color: $basicColorPrimary;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: $mainColorSecondary;
    font-size: 1.2rem;
    &:hover {
      background-color: $btnColorSecondary;
      color: $basicColorPrimary;
    }
  }