@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
/* @font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}

@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}

@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}

$astralSistersFont: AstralSisters;
$clanProBlkFont: ClanProBlk;
$clanProBoldFont: ClanProBold;
$clanProMediuFont: ClanProMedium;
$clanProRegularFont: ClanProRegular;
$clanProRgNewsFont: ClanProRgNews;
$clanProUltraFont: ClanProUltra;
$sarabunBoldFont: SarabunBold;
$sarabunExtraBoldFont: SarabunExtraBold;
$sarabunLightFont: SarabunLight;
$sarabunLightItalicFont: SarabunLightItalic;
$sarabunMediumFont: SarabunMedium;
$sarabunRegularFont: SarabunRegular;
$sarabunSemiBoldFont: SarabunSemiBold;
$playFairFont: "Playfair Display", serif; */
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.job-offer-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  background-color: #ffffff;
  padding: 5rem 0;
  position: relative;
}
.job-offer-section .bg-skew {
  height: 7rem;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  z-index: 0;
}
.job-offer-section .top {
  top: -6.5rem;
  -webkit-clip-path: polygon(0 89%, 100% 14%, 100% 100%, 0 100%);
          clip-path: polygon(0 89%, 100% 14%, 100% 100%, 0 100%);
}
.job-offer-section .bottom {
  height: 25rem;
  top: 32rem;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 69%, 0 48%);
          clip-path: polygon(0% 0%, 100% 0%, 100% 69%, 0 48%);
}
.job-offer-section .job-offer-subcontainer {
  width: 1024px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  row-gap: 3rem;
}
.job-offer-section .job-offer-subcontainer .jo-title {
  color: #014da1;
  font-weight: 500;
  font-size: 1.8rem;
}
.job-offer-section .job-offer-subcontainer .job-offer-info-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  justify-content: center;
  -moz-column-gap: 3rem;
       column-gap: 3rem;
}
.job-offer-section .job-offer-subcontainer .jo-video-container {
  position: relative;
}
.job-offer-section .job-offer-subcontainer .deco-video {
  position: absolute;
  width: 7rem;
  top: -5rem;
  left: -3rem;
}
.job-offer-section .job-offer-subcontainer .jo-info-video {
  width: 100%;
}
.job-offer-section .job-offer-subcontainer .jo-content-title {
  color: #014da1;
  font-style: italic;
  font-size: 1.3rem;
}
.job-offer-section .job-offer-subcontainer .jo-text-content {
  margin-top: 2rem;
  color: #7a7a7a;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .job-offer-section {
    padding: 2rem 0;
  }
  .job-offer-section .job-offer-subcontainer {
    margin: 0 1rem;
    row-gap: 6rem;
  }
  .job-offer-section .job-offer-subcontainer .job-offer-info-container {
    display: grid;
    row-gap: 2rem;
    grid-template-columns: 1fr;
  }
  .job-offer-section .job-offer-subcontainer .jo-content-container {
    z-index: 2;
  }
  .job-offer-section .job-offer-subcontainer .jo-title {
    font-size: 1.5rem;
  }
  .job-offer-section .top {
    top: -6.5rem;
  }
  .job-offer-section .bottom {
    top: 35rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .job-offer-section {
    padding: 2rem 0;
  }
  .job-offer-section .job-offer-subcontainer {
    margin: 0 10%;
    row-gap: 6rem;
  }
  .job-offer-section .job-offer-subcontainer .job-offer-info-container {
    display: grid;
    row-gap: 2rem;
    grid-template-columns: 1fr;
  }
  .job-offer-section .job-offer-subcontainer .jo-content-container {
    z-index: 2;
  }
  .job-offer-section .job-offer-subcontainer .jo-title {
    font-size: 1.5rem;
  }
  .job-offer-section .top {
    top: -6rem;
  }
  .job-offer-section .bottom {
    top: 38rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .job-offer-section .job-offer-subcontainer {
    margin: 0 10%;
    row-gap: 6rem;
  }
  .job-offer-section .job-offer-subcontainer .jo-content-container {
    z-index: 2;
  }
  .job-offer-section .job-offer-subcontainer .jo-title {
    font-size: 1.5rem;
  }
  .job-offer-section .top {
    top: -6rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .job-offer-subcontainer {
    margin: 0 1rem;
  }
}/*# sourceMappingURL=JobOfferSection.css.map */