@import "../../sass/variables";

.banner-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  .banner-subcontainer {
    width: 90%;
    display: grid;
    grid-template-columns: 60% 40%;
    column-gap: 3rem;
    align-items: flex-start;
    .banner-info-container {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
    }
    .banner-title {
      width: 70%;
      font-size: 2rem;
      font-weight: 300;
      color: $mainColorSecondary;
    }
    .main-img {
      width: 100%;
      height: auto;
    }
    .second-img {
      width: 50%;
      height: 100%;
    }
    .banner-btn-info-container {
      width: 55%;
      display: flex;
      position: relative;
    }
    .btn-banner-info {
      width: 100%;
      border: 3px solid $btnColorSecondary;
      padding: 0.3rem 2rem;
      border-radius: 1.5rem;
      background-color: $basicColorPrimary;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: $mainColorSecondary;
      font-size: 1.2rem;
      &:hover {
        background-color: $btnColorSecondary;
        color: $basicColorPrimary;
      }
    }
    .btn-icon {
      width: 5rem;
      position: absolute;
      right: -20%;
      top: 1rem;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .banner-section {
    padding-bottom: 10rem;
    flex-direction: column;
    row-gap: 2rem;
    margin-top: 2rem !important;
    .online-pay-mobile-btn-container {
      width: 100%;
      display: flex;
      justify-content: center;
      .online-pay-mobile-btn {
        background-color: $btnColorPrimary;
        padding: 0.25rem 1.5rem;
        border-radius: 1rem;
        font-weight: bold;
        &:link {
          color: $basicColorPrimary;
          text-decoration: none;
        }
        &:visited {
          color: $basicColorPrimary;
        }
        &:active {
          color: $basicColorPrimary;
        }
        &:hover {
          color: $btnColorPrimary;
          border: 1px solid $btnColorPrimary;
          background-color: $basicColorPrimary;
        }
      }
    }
    .banner-subcontainer {
      grid-template-columns: 1fr;
      .banner-info-container {
        align-items: center;
      }
      .main-img {
        width: 100%;
      }
      .banner-btn-info-container {
        width: unset;
      }
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .banner-section {
    padding-bottom: 10rem;
    flex-direction: column;
    row-gap: 2rem;
    margin-top: 2rem !important;
    .online-pay-mobile-btn-container {
      width: 100%;
      display: flex;
      justify-content: center;
      .online-pay-mobile-btn {
        background-color: $btnColorPrimary;
        padding: 0.25rem 1.5rem;
        border-radius: 1rem;
        font-weight: bold;
        &:link {
          color: $basicColorPrimary;
          text-decoration: none;
        }
        &:visited {
          color: $basicColorPrimary;
        }
        &:active {
          color: $basicColorPrimary;
        }
        &:hover {
          color: $btnColorPrimary;
          border: 1px solid $btnColorPrimary;
          background-color: $basicColorPrimary;
        }
      }
    }
    .banner-subcontainer {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      .banner-info-container {
        align-items: center;
      }
      .main-img {
        width: 100%;
        height: 80%;
      }
      .banner-btn-info-container {
        width: unset;
      }
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .banner-section {
    padding-bottom: 10rem;
    flex-direction: column;
    row-gap: 2rem;
    .online-pay-mobile-btn-container {
      width: 100%;
      display: flex;
      justify-content: center;
      .online-pay-mobile-btn {
        background-color: $btnColorPrimary;
        padding: 0.25rem 1.5rem;
        border-radius: 1rem;
        font-weight: bold;
        &:link {
          color: $basicColorPrimary;
          text-decoration: none;
        }
        &:visited {
          color: $basicColorPrimary;
        }
        &:active {
          color: $basicColorPrimary;
        }
        &:hover {
          color: $btnColorPrimary;
          border: 1px solid $btnColorPrimary;
          background-color: $basicColorPrimary;
        }
      }
    }
    .banner-subcontainer {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      .banner-info-container {
        align-items: center;
      }
      .banner-btn-info-container {
        width: unset;
      }
    }
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
  .online-pay-mobile-btn-container {
    display: none;
  }
  .banner-subcontainer {
    align-items: flex-end !important;
  }
  .banner-info-container {
    transform: scale(0.75);
    position: relative;
    top: -4rem;
  }
}

//Responsive Screen Small
@media screen and (min-width: $screenSm) and (max-width: calc($screenHd - 1px)) {
  .online-pay-mobile-btn-container {
    display: none;
  }
  .banner-info-container {
    transform: scale(0.8);
    position: relative;
    top: -4rem;
  }
  .btn-icon {
    right: -10% !important;
  }
}

//Responsive Screen HD
@media screen and (min-width: $screenHd) {
  .online-pay-mobile-btn-container {
    display: none;
  }
  .btn-icon {
    right: -10% !important;
  }
}
