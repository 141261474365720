@import "../../sass/variables";

.popup-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $basicColorPrimaryOpa;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .popup-subcontainer {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid $mainColorSecondary;
    background-color: $basicColorPrimary;
    border-radius: 1rem;
    padding: 0 1rem;
    padding-bottom: 1.5rem;
    .popup-header-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $mainColorSecondary;
      border-top: 1px solid $mainColorSecondary;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      overflow: hidden;
      padding: 0.5rem 1rem;
    }
    .ph-title {
      width: 100%;
      text-align: center;
      font-size: 1.5rem;
      color: $basicColorPrimary;
      font-weight: bold;
    }
    .ph-close-icon-container {
      width: 1rem;
      height: 1rem;
    }
    .ph-close-icon {
      width: 100%;
      height: 100%;
      object-fit: contain;
      cursor: pointer;
    }
    .popup-body-container {
      width: 95%;
      padding: 0.5rem 1rem;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .pb-subtitle {
      text-align: center;
      color: $mainColorSecondary;
      font-weight: 300;
      font-size: 1.25rem;
    }
    .pb-authorized-icon-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 2rem;
    }
    .pb-authorized-icon {
      width: 6rem;
      height: auto;
    }
    .deco-img-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .pb-deco-img {
      width: 8rem;
      height: auto;
    }
    .pb-description-txt {
      text-align: center;
      color: $mainColorSecondary;
      font-weight: 300;
      font-size: 1.25rem;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .popup-subcontainer {
    width: 80% !important;
    .ph-title {
      font-size: 1.25rem !important;
    }
    .pb-subtitle {
      font-size: 1rem !important;
    }
    .pb-authorized-icon-container {
      margin: 0 !important;
    }
    .pb-authorized-icon {
      width: 4rem !important;
    }
    .pb-deco-img {
      width: 5rem !important;
    }
    .pb-description-txt {
      font-size: 1rem !important;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .popup-subcontainer {
    width: 80% !important;
    .ph-title {
      font-size: 1.25rem !important;
    }
    .pb-subtitle {
      font-size: 1rem !important;
    }
    .pb-authorized-icon-container {
      margin: 0 !important;
    }
    .pb-authorized-icon {
      width: 4rem !important;
    }
    .pb-deco-img {
      width: 5rem !important;
    }
    .pb-description-txt {
      font-size: 1rem !important;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .popup-subcontainer {
    width: 60% !important;
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
  .popup-subcontainer {
    width: 60% !important;
  }
}
