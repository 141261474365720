@import "../../sass/variables";

.financial-process-section {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5rem 0;
  background-color: $basicColorPrimary;
  position: relative;
  .bg-skew {
    height: 7rem;
    width: 100%;
    background-color: $basicColorPrimary;
    position: absolute;
    z-index: 0;
  }
  .bottom {
    top: 50rem;
    clip-path: polygon(0% 0%, 100% 0%, 100% 24%, 0% 75%);
  }
  .financial-process-subcontainer {
    width: 1024px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    row-gap: 5rem;
    .fp-titles {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .fp-title {
      color: $mainColorSecondary;
      font-weight: 500;
      font-size: 2rem;
      text-align: center;
    }
    .fp-subtitle {
      margin-left: 20%;
      color: $textColorPrimary;
      font-weight: 300;
      font-size: 1rem;
    }
    .fp-steps-container-mobile {
      display: flex;
      justify-content: center;
      width: 40%;
      height: 25rem;
      margin: 0 1rem;
    }
    .fp-steps-container {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      align-items: baseline;
    }
    .step-img {
      width: 10rem;
      height: 100%;
    }
    .step-number {
      border: 2px solid $btnColorSecondary;
      border-radius: 1rem;
      padding: 0.25rem 1rem;
      color: $mainColorSecondary;
    }
    .step-text-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      row-gap: 1rem;
    }
    .step-subtitle {
      color: $mainColorSecondary;
      font-size: 0.9rem;
    }
    .step-content-text {
      color: $textColorPrimary;
      font-size: 0.9rem;
    }
    .next-icon {
      width: 0.9rem;
      height: 100%;
      margin: 0 0.5rem;
    }
    .step-container {
      display: flex;
      align-items: baseline;
    }
    .step-info-container {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      align-items: center;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .financial-process-section {
    .fp-titles {
      align-items: center;
      row-gap: 1rem;
      .fp-title {
        font-size: 1.2rem !important;
      }
      .fp-subtitle {
        width: 40%;
        margin-left: 0;
      }
    }
    .fp-steps-container-tablet {
      display: none;
    }
    .fp-steps-container {
      display: none !important;
    }
    .fp-btn-container {
      display: flex;
      justify-content: center;
    }
    .bottom {
      top: 52rem;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .financial-process-section {
    .fp-titles {
      align-items: center;
      row-gap: 1rem;
      .fp-title {
        font-size: 1.2rem !important;
      }
      .fp-subtitle {
        width: 40%;
        margin-left: 0;
      }
    }
    .fp-steps-container-tablet {
      display: none;
    }
    .fp-steps-container {
      display: none !important;
    }
    .fp-btn-container {
      display: flex;
      justify-content: center;
    }
    .bottom {
      top: 52rem;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .financial-process-section {
    .fp-steps-container-mobile {
      display: none !important;
    }
    .fp-steps-container-tablet {
      width: 90%;
    }
    .fp-steps-container {
      display: none !important;
    }
    .fp-btn-container {
      display: flex;
      justify-content: center;
    }
    .bottom {
      top: 47rem;
    }
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
  .financial-process-section {
    .financial-process-subcontainer {
      margin: 0 1rem;
    }
    .fp-steps-container-mobile {
      display: none !important;
    }
    .fp-steps-container-tablet {
      display: none;
    }
    .fp-btn-container {
      display: flex;
      justify-content: center;
    }
  }
}

//Responsive Screen Small >
@media screen and (min-width: $screenSm) {
  .financial-process-section {
    .fp-steps-container-mobile {
      display: none !important;
    }
    .fp-steps-container-tablet {
      display: none;
    }
    .fp-btn-container {
      display: flex;
      justify-content: center;
    }
  }
}
