@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
/* @font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}

@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}

@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}

$astralSistersFont: AstralSisters;
$clanProBlkFont: ClanProBlk;
$clanProBoldFont: ClanProBold;
$clanProMediuFont: ClanProMedium;
$clanProRegularFont: ClanProRegular;
$clanProRgNewsFont: ClanProRgNews;
$clanProUltraFont: ClanProUltra;
$sarabunBoldFont: SarabunBold;
$sarabunExtraBoldFont: SarabunExtraBold;
$sarabunLightFont: SarabunLight;
$sarabunLightItalicFont: SarabunLightItalic;
$sarabunMediumFont: SarabunMedium;
$sarabunRegularFont: SarabunRegular;
$sarabunSemiBoldFont: SarabunSemiBold;
$playFairFont: "Playfair Display", serif; */
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.job-offer-info-section {
  width: 100%;
  display: flex;
  justify-content: center;
}
.job-offer-info-section .job-offer-info-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 4rem 0;
  row-gap: 2rem;
}
.job-offer-info-section .job-offer-info-subcontainer .joi-skill-container {
  width: 65%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  color: #55595C;
}
.job-offer-info-section .job-offer-info-subcontainer .joi-job-info-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.job-offer-info-section .job-offer-info-subcontainer .joi-ji-grid {
  display: flex;
  align-items: center;
  color: #55595C;
  -moz-column-gap: 5rem;
       column-gap: 5rem;
}
.job-offer-info-section .job-offer-info-subcontainer .joi-skill-title {
  color: #014da1;
  font-weight: 500;
  font-size: 2rem;
}
.job-offer-info-section .job-offer-info-subcontainer .joi-skill-elm-container {
  display: flex;
  align-items: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  flex-wrap: wrap;
}
.job-offer-info-section .job-offer-info-subcontainer .joi-se-elm {
  color: #55595C;
  border: 1px solid #55595C;
  font-size: 1rem;
  border-radius: 0.5rem;
  padding: 0.25rem 1rem;
  text-align: center;
  font-weight: 400;
}
.job-offer-info-section .job-offer-info-subcontainer .joi-description-container {
  width: 65%;
  color: #55595C;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.job-offer-info-section .job-offer-info-subcontainer .joi-func-container {
  width: 65%;
  color: #55595C;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.job-offer-info-section .job-offer-info-subcontainer .joi-benefits-container {
  width: 65%;
  color: #55595C;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.job-offer-info-section .job-offer-info-subcontainer .joi-requirements-container {
  width: 65%;
  color: #55595C;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.job-offer-info-section .job-offer-info-subcontainer .joi-skills-container {
  width: 65%;
  color: #55595C;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .job-offer-info-subcontainer {
    width: 90% !important;
    align-items: center !important;
  }
  .job-offer-info-subcontainer .joi-skill-elm-container {
    row-gap: 1rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .job-offer-info-subcontainer {
    width: 80% !important;
    align-items: center !important;
  }
  .job-offer-info-subcontainer .joi-skill-elm-container {
    row-gap: 1rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .job-offer-info-subcontainer {
    width: 90% !important;
    align-items: center !important;
  }
  .job-offer-info-subcontainer .joi-skill-elm-container {
    row-gap: 1rem;
  }
}/*# sourceMappingURL=JobOfferInfoSection.css.map */