@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
/* @font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}

@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}

@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}

$astralSistersFont: AstralSisters;
$clanProBlkFont: ClanProBlk;
$clanProBoldFont: ClanProBold;
$clanProMediuFont: ClanProMedium;
$clanProRegularFont: ClanProRegular;
$clanProRgNewsFont: ClanProRgNews;
$clanProUltraFont: ClanProUltra;
$sarabunBoldFont: SarabunBold;
$sarabunExtraBoldFont: SarabunExtraBold;
$sarabunLightFont: SarabunLight;
$sarabunLightItalicFont: SarabunLightItalic;
$sarabunMediumFont: SarabunMedium;
$sarabunRegularFont: SarabunRegular;
$sarabunSemiBoldFont: SarabunSemiBold;
$playFairFont: "Playfair Display", serif; */
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.payment-methods-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  padding: 5rem 0;
}
.payment-methods-section .payment-methods-subcontainer {
  width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.payment-methods-section .payment-methods-subcontainer .pm-info-title {
  color: #014da1;
  font-weight: 500;
  font-size: 2rem;
}
.payment-methods-section .payment-methods-subcontainer .pm-info-content {
  color: #7a7a7a;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.payment-methods-section .payment-methods-subcontainer .pm-options-container {
  display: flex;
  align-items: center;
}
.payment-methods-section .payment-methods-subcontainer .option-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.payment-methods-section .payment-methods-subcontainer .pm-btn-container {
  width: 70%;
  display: flex;
  justify-content: center;
}
.payment-methods-section .payment-methods-subcontainer .pm-opt-img {
  width: 20rem;
  height: 100%;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .payment-methods-section {
    padding: 0 !important;
    margin: 7rem 0 !important;
  }
  .payment-methods-section .payment-methods-subcontainer {
    display: grid;
  }
  .payment-methods-section .payment-methods-subcontainer .pm-info-container {
    margin: 0 1rem;
  }
  .payment-methods-section .payment-methods-subcontainer .pm-options-container {
    display: grid;
    grid-template-rows: 1fr 1fr;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .payment-methods-section {
    padding: 0 !important;
    margin: 7rem 0 !important;
  }
  .payment-methods-section .payment-methods-subcontainer {
    display: grid;
  }
  .payment-methods-section .payment-methods-subcontainer .pm-info-container {
    margin: 0 10%;
  }
  .payment-methods-section .payment-methods-subcontainer .pm-options-container {
    display: grid;
    grid-template-rows: 1fr 1fr;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .payment-methods-section .payment-methods-subcontainer {
    display: grid;
    grid-template-rows: 1fr 1fr;
  }
  .payment-methods-section .payment-methods-subcontainer .pm-info-container {
    margin: 0 10%;
  }
  .payment-methods-section .payment-methods-subcontainer .pm-options-container {
    justify-content: space-around;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .payment-methods-subcontainer {
    margin: 0 1rem;
  }
}/*# sourceMappingURL=PaymentSection.css.map */