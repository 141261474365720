@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
/* @font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}

@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}

@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}

$astralSistersFont: AstralSisters;
$clanProBlkFont: ClanProBlk;
$clanProBoldFont: ClanProBold;
$clanProMediuFont: ClanProMedium;
$clanProRegularFont: ClanProRegular;
$clanProRgNewsFont: ClanProRgNews;
$clanProUltraFont: ClanProUltra;
$sarabunBoldFont: SarabunBold;
$sarabunExtraBoldFont: SarabunExtraBold;
$sarabunLightFont: SarabunLight;
$sarabunLightItalicFont: SarabunLightItalic;
$sarabunMediumFont: SarabunMedium;
$sarabunRegularFont: SarabunRegular;
$sarabunSemiBoldFont: SarabunSemiBold;
$playFairFont: "Playfair Display", serif; */
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.not-found-page-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.not-found-page-section .not-found-page-subcontainer {
  width: 1024px;
  display: flex;
  align-items: center;
  padding-top: 10%;
}
.not-found-page-section .info-err-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2rem;
}
.not-found-page-section .info-err-container h1 {
  font-weight: 200;
  font-size: 2.1rem;
  margin-left: 0.5rem;
}
.not-found-page-section .info-err-container p {
  width: 80%;
  margin-top: 1rem;
  margin-left: 0.5rem;
}
.not-found-page-section .info-err-container .logo-otacc {
  width: 15rem;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .not-found-page-section .not-found-page-subcontainer {
    flex-direction: column;
    padding: 10% 0;
    margin: 15% 0;
  }
  .not-found-page-section .info-err-container {
    align-items: center;
    margin: 0 10%;
  }
  .not-found-page-section .img-err-container {
    margin-top: 3rem;
    width: 90%;
  }
  .not-found-page-section .img-err-container .img-err {
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .not-found-section .info-err-container {
    margin-left: 10%;
  }
  .not-found-section .img-err-container {
    margin-right: 10%;
  }
}/*# sourceMappingURL=BodyNotFoundPage.css.map */